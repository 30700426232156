
const mainPageDarkIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/HomeLeftIcons/Admin/mainPageDark.svg";
const mainPageLightIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/HomeLeftIcons/Admin/mainPageDark.svg";
const mainPageGoldenIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/HomeLeftIcons/Admin/mainPageGolden.svg";

const websitesDarkIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/HomeLeftIcons/Admin/websitesDark.svg";
const websitesLightIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/HomeLeftIcons/Admin/websitesDark.svg";
const websitesGoldenIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/HomeLeftIcons/Admin/websitesGolden.svg";

const commissionStructureDarkIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/HomeLeftIcons/Admin/commissionStructureDark.svg";
const commissionStructureLightIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/HomeLeftIcons/Admin/commissionStructureDark.svg";
const commissionStructureGoldenIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/HomeLeftIcons/Admin/commissionStructureGolden.svg";

const paymentHistoryDarkIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/HomeLeftIcons/Admin/paymentHistoryDark.svg";
const paymentHistoryLightIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/HomeLeftIcons/Admin/paymentHistoryDark.svg";
const paymentHistoryGoldenIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/HomeLeftIcons/Admin/paymentHistoryGolden.svg";

const accountDarkIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/HomeLeftIcons/Admin/accountDark.svg";
const accountLightIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/HomeLeftIcons/Admin/accountDark.svg";
const accountGoldenIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/HomeLeftIcons/Admin/accountGolden.svg";

const contactsDarkIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/HomeLeftIcons/Admin/contactsDark.svg";
const contactsLightIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/HomeLeftIcons/Admin/contactsDark.svg";
const contactsGoldenIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/HomeLeftIcons/Admin/contactsGolden.svg";

const affiliateLinksDarkIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/HomeLeftIcons/Admin/affiliateLinksDark.svg";
const affiliateLinksLightIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/HomeLeftIcons/Admin/affiliateLinksDark.svg";
const affiliateLinksGoldenIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/HomeLeftIcons/Admin/affiliateLinksGolden.svg";

const promoCodesDarkIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/HomeLeftIcons/Admin/promoCodesDark.svg";
const promoCodesLightIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/HomeLeftIcons/Admin/promoCodesDark.svg";
const promoCodesGoldenIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/HomeLeftIcons/Admin/promoCodesGolden.svg";

const mediaDarkIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/HomeLeftIcons/Admin/mediaDark.svg";
const mediaLightIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/HomeLeftIcons/Admin/mediaDark.svg";
const mediaGoldenIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/HomeLeftIcons/Admin/mediaGolden.svg";

const summaryDarkIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/HomeLeftIcons/Admin/summaryDark.svg";
const summaryLightIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/HomeLeftIcons/Admin/summaryDark.svg";
const summaryGoldenIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/HomeLeftIcons/Admin/summaryGolden.svg";

const fullReportDarkIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/HomeLeftIcons/Admin/fullReportDark.svg";
const fullReportLightIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/HomeLeftIcons/Admin/fullReportDark.svg";
const fullReportGoldenIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/HomeLeftIcons/Admin/fullReportGolden.svg";

const marketingToolsDarkIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/HomeLeftIcons/Admin/marketingToolsDark.svg";
const marketingToolsLightIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/HomeLeftIcons/Admin/marketingToolsDark.svg";
const marketingToolsGoldenIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/HomeLeftIcons/Admin/marketingToolsGolden.svg";

const playerReportDarkIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/HomeLeftIcons/Admin/playerReportDark.svg";
const playerReportLightIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/HomeLeftIcons/Admin/playerReportLight.svg";
const playerReportGoldenIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/HomeLeftIcons/Admin/playerReportGolden.svg";

const subAffiliateReportDarkIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/HomeLeftIcons/Admin/subAffiliateReportDark.svg";
const subAffiliateReportLightIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/HomeLeftIcons/Admin/subAffiliateReportDark.svg";
const subAffiliateReportGoldenIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/HomeLeftIcons/Admin/subAffiliateReportGolden.svg";


export const homeLeftData = [
    {
        name: null,
        to: null,
        icon1: null,
        icon2: null,
        subMenu: [
            {
                id: 0,
                name: "Main Page",
                to: "/main-page",
                icon1: mainPageDarkIcon,
                icon2: mainPageLightIcon,
                icon3: mainPageGoldenIcon,
            },
            {
                id: 1,
                name: "Websites",
                to: "/websites",
                icon1: websitesDarkIcon,
                icon2: websitesLightIcon,
                icon3: websitesGoldenIcon,
            },
            {
                id: 2,
                name: "Commission Structure",
                to: "/commission-structure",
                icon1: commissionStructureDarkIcon,
                icon2: commissionStructureLightIcon,
                icon3: commissionStructureGoldenIcon,
            },
            {
                id: 3,
                name: "Payment History",
                to: "/payment-history",
                icon1: paymentHistoryDarkIcon,
                icon2: paymentHistoryLightIcon,
                icon3: paymentHistoryGoldenIcon,
            },
            {
                id: 5,
                name: "Account",
                to: "/account",
                icon1: accountDarkIcon,
                icon2: accountLightIcon,
                icon3: accountGoldenIcon,
            },
            {
                id: 6,
                name: "Contacts",
                to: "/contacts",
                icon1: contactsDarkIcon,
                icon2: contactsLightIcon,
                icon3: contactsGoldenIcon,
            },
        ],
    },
    {
        name: `Marketing`,
        to: null,
        icon1: null,
        icon2: null,
        subMenu: [
            {
                id: 0,
                name: "Affiliate Links",
                to: "/affiliate-links",
                icon1: affiliateLinksDarkIcon,
                icon2: affiliateLinksLightIcon,
                icon3: affiliateLinksGoldenIcon,
            },
            {
                id: 1,
                name: "Marketing Tools",
                to: "/marketing-tools",
                icon1: marketingToolsDarkIcon,
                icon2: marketingToolsLightIcon,
                icon3: marketingToolsGoldenIcon,
            },
            // {
            //     id: 1,
            //     name: "Promo Codes",
            //     to: "/promo-codes",
            //     icon1: promoCodesDarkIcon,
            //     icon2: promoCodesLightIcon,
            //     icon3: promoCodesGoldenIcon,
            // },
            // {
            //     id: 2,
            //     name: "Media",
            //     to: "/media",
            //     icon1: mediaDarkIcon,
            //     icon2: mediaLightIcon,
            //     icon3: mediaGoldenIcon,
            // },
        ],
    },
    {
        name: `Reports`,
        to: null,
        icon1: null,
        icon2: null,
        subMenu: [
            {
                id: 0,
                name: "Bet History",
                to: "/affiliate-bet-history",
                icon1: summaryDarkIcon,
                icon2: summaryLightIcon,
                icon3: summaryGoldenIcon,
            },
            {
                id: 1,
                name: "Full Report",
                to: "/full-report",
                icon1: fullReportDarkIcon,
                icon2: fullReportLightIcon,
                icon3: fullReportGoldenIcon,
            },
            // {
            //     id: 2,
            //     name: "Marketing Tools",
            //     to: "/marketing-tools",
            //     icon1: marketingToolsDarkIcon,
            //     icon2: marketingToolsLightIcon,
            //     icon3: marketingToolsGoldenIcon,
            // },
            {
                id: 2,
                name: "Player Report",
                to: "/player-report",
                icon1: playerReportDarkIcon,
                icon2: playerReportLightIcon,
                icon3: playerReportGoldenIcon,
            },
            {
                id: 3,
                name: "Sub-affiliate Report",
                to: "/sub-affiliate-report",
                icon1: subAffiliateReportDarkIcon,
                icon2: subAffiliateReportLightIcon,
                icon3: subAffiliateReportGoldenIcon,
            },
        ],
    },
];