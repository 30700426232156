import React, { Suspense, useEffect, useRef } from 'react';
import './App.css';
import './Components/Shared/CustomInputField/Admin/AdminCustomInputField.css';

import { Route, Routes, useNavigate, useSearchParams } from 'react-router-dom';

import { useThemeColor } from './hooks/useThemeColor';
import { useAdminStatics } from './hooks/useAdminStatics';

import { fetchLoginUserData, fetchLoginUserWallet, userDetail } from './Redux/userSlice';
import { useDispatch, useSelector } from 'react-redux';

import { setInnerLoaderFalse, setInnerLoaderTrue } from './Redux/loaderSlice';

import { routesListAuth, routesListWithPageName, } from './Data/Admin/AdminRoutingsData';

// custom-sweet-alert
import CustomSweetAlert2 from './Components/Shared/CustomSweetAlert/CustomSweetAlert2';
import SuccessDetails from './Components/Shared/DetailsModals/SuccessDetails/SuccessDetails';

import { AnimatePresence, motion } from "framer-motion";

// layouts
import AdminBaseLayout from './Layouts/BaseLayout/Admin/AdminBaseLayout';
// import LayoutWithBackButtonPageName from './Layouts/LayoutWithBackButtonPageName/LayoutWithBackButtonPageName';
// import { useSliderCardMenu } from './hooks/useSliderCardMenu';
// import DynamicGames from './Pages/GamePages/DynamicGames/DynamicGames';
import PrivateRoute from './Pages/PrivateRoute/PrivateRoute';
import PublicRoute from './Pages/PrivateRoute/PublicRoute';

// pages
const AdminHome = React.lazy(() => import('./Pages/Home/AdminHome'));

const Loader = React.lazy(() => import('./Components/Shared/Loader/Loader'));
const Progressbar = React.lazy(() => import('./Components/Shared/Progressbar/Progressbar'));
const CustomAlert = React.lazy(() => import('./Components/Shared/CustomAlert/CustomAlert'));
// const MovableHome = React.lazy(() => import('./Components/Shared/MovableHome/MovableHome'));

// export const rootUrl = process.env.REACT_APP_BASE_URL;
export const localUrl = `http://192.168.0.209:81/`;
export const serverUrl = `https://glo365.app`;
// export const rootUrl = localUrl;
export const rootUrl = serverUrl;
export const userKey = "affiliateuserkeys";

export const exchangeLaunch_site = "https://9imax.com/";

// export const origin = process.env.REACT_APP_BASE_ORIGIN;
export const RE_DIGIT = new RegExp(/^\d+$/);

function App() {

  const { progressBar, percentage, } = useAdminStatics();
  const { colorMode } = useThemeColor();

  // console.log(process.env.REACT_APP_BASE_URL);

  const user = useSelector(userDetail);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams,] = useSearchParams();

  // const { sliderCardMenuData } = useSliderCardMenu();

  useEffect(() => {
    if (!user?.is_login) {
      // navigate("/login");
      const refFromParam = searchParams.get("ref");
      if (refFromParam) {
        localStorage.setItem("ref", refFromParam);
        // dispatch(setInnerLoaderTrue());
        navigate("/login");
      }

      dispatch(setInnerLoaderFalse());
    }
    let stringValue = localStorage.getItem(userKey);
    if (stringValue !== null) {
      let value = JSON.parse(stringValue);

      localStorage.setItem(userKey, JSON.stringify({
        is_login: true,
        value: value.value
      }));
      dispatch(setInnerLoaderTrue());
      // dispatch(fetchLoginUserData());
      dispatch(fetchLoginUserWallet());
      // navigate("/");
    } else {
      navigate("/login");
    }
    dispatch(setInnerLoaderFalse());
  }, [dispatch, user?.is_login, searchParams, navigate]);

  useEffect(() => {
    // dispatch(fetchGame());
    // dispatch(getAllGameRequest());
    dispatch(fetchLoginUserData());
    dispatch(setInnerLoaderFalse());
  }, [dispatch]);

  const installableBtnRef = useRef();

  useEffect(() => {
    sessionStorage.removeItem("isFirstLoad");
    const ready = (e) => {
      e.preventDefault();
      installableBtnRef.current = e;
    };

    window.addEventListener("beforeinstallprompt", ready);
    return () => {
      window.removeEventListener("beforeinstallprompt", ready);
    };
  }, []);

  // const avoidableRoutes = [
  //   `download_app_promo`,
  //   `launch`,
  //   `lottery-events`,
  //   `about`,
  //   `lottery-live-results`,
  // ];

  let parentUrl = (window.location !== window.parent.location) ? document.referrer : document.location.href;
  let url_val = parentUrl?.split('/');
  let currentRoute = url_val?.[3];

  // console.log(currentRoute, promotionalsBG);

  return (
    <Suspense
      fallback={
        <div
        style={{
          width: "100dvw",
          height: "100dvh",
          backgroundColor: "var(--white)",
        }}
        ></div>
      }
    >
      <div className={"App row g-0 justify-content-center " + colorMode}
        style={{
          backgroundColor: "",
          // backgroundImage: `url(${(currentRoute === "download_app_promo") ? (rootUrl + promotionalsBG[0]?.image) : appBG && (rootUrl + appBG[0]?.image)})`,
        }}>
        {
          (
            percentage <= 100 &&
            progressBar
          ) ?
            <Progressbar percentage={percentage} />
            :
            <>
              <div className={(currentRoute === "download_app_promo") ? "" : ""} id="main">
                {/* {
                  (getStartRef && (!avoidableRoutes?.includes(currentRoute))) &&
                  <MovableHome />
                } */}
                <AnimatePresence>
                  <motion.div
                    initial={{ opacity: 0, x: "-50%" }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: "50%" }}
                    transition={{ duration: 0.3, ease: "easeInOut" }}
                  >
                    <Routes>
                      <Route index element={<AdminBaseLayout><AdminHome installableBtnRef={installableBtnRef} /></AdminBaseLayout>} />

                      {
                        (routesListAuth?.map((item, index) =>
                          (item?.public === true) ?
                            <Route key={item?.id || index} path={item?.path} element={
                              <PublicRoute>
                                {item?.component}
                              </PublicRoute>
                            }
                            />
                            :
                            <Route key={item?.id || index} path={item?.path} element={
                              item?.component
                            }
                            />
                        ))
                      }

                      {/* {
                        (routesListWithPageName?.map((item, index) =>
                          (item?.private === true) ?
                            <Route key={item?.id || index} path={item?.path} element={
                              <PrivateRoute>
                                <LayoutWithBackButtonPageName pageTitle={item?.pageTitle}>
                                  {item?.component}
                                </LayoutWithBackButtonPageName>
                              </PrivateRoute>
                            } />
                            :
                            <Route key={item?.id || index} path={item?.path} element={<LayoutWithBackButtonPageName pageTitle={item?.pageTitle}>
                              {item?.component}
                            </LayoutWithBackButtonPageName>} />
                        ))
                      } */}
                      {
                        (routesListWithPageName?.map((item, index) =>
                          (item?.private === true) ?
                            <Route key={item?.id || index} path={item?.path} element={
                              <PrivateRoute>
                                <AdminBaseLayout>
                                  {item?.component}
                                </AdminBaseLayout>
                              </PrivateRoute>
                            } />
                            :
                            <Route key={item?.id || index} path={item?.path} element={<>
                              {item?.component}
                            </>} />
                        ))
                      }
                    </Routes>
                  </motion.div>
                </AnimatePresence>
              </div>
              <CustomAlert />
              <CustomSweetAlert2 />
              <SuccessDetails />
            </>
        }

      </div>
    </Suspense>
  );
}

export default App;
