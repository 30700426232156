import React, { useEffect, useState, } from "react";
import { Link, useLocation, } from "react-router-dom";

import { useAdminStatics } from "../../../hooks/useAdminStatics";

import { motion } from "framer-motion"

import AdminNavigation from "../../../Components/Shared/Navigation/AdminNavigation";
import AdminLeftSideBar from "../../../Components/Shared/LeftSideBar/AdminLeftSideBar";
import AdminFooter from "../../../Components/Shared/Footer/Admin/AdminFooter";

// import { useSliderCardMenu } from "../../hooks/useSliderCardMenu";
import { homeLeftData } from "../../../Data/Admin/AdminHomePageData";

import Offcanvas from "react-bootstrap/Offcanvas";

import { GiHamburgerMenu } from "react-icons/gi";
import "./AdminBaseLayout.css";

// const GetStarted = React.lazy(() => import("../../../Pages/GetStarted/GetStarted"));

const AdminBaseLayout = ({ children }) => {

    const { logo } = useAdminStatics();
    const [selectedLeftBarData, setSelectedLeftBarData] = useState(homeLeftData?.[0]?.subMenu?.[0]);

    const [sideBar, setSidebar] = useState(true);
    const [offCanvas, setOffCanvas] = useState(false);

    const toggleSidebar = () => {
        setSidebar(!sideBar);
    }

    const toggleOffCanvas = () => {
        setOffCanvas(!offCanvas);
    }

    const location = useLocation();

    useEffect(() => {
        const currentPath = location.pathname;
        let foundItem = null;

        homeLeftData?.forEach(section => {
            section.subMenu.forEach(item => {
                if (item?.to === currentPath) {
                    foundItem = item;
                }
            });
        });

        if (foundItem) {
            setSelectedLeftBarData(foundItem);
        }
    }, [location, homeLeftData]);

    return (
        <div>
            {
                // getStartRef === null ?
                //     <GetStarted setTrueStart={setTrueStart} />
                //     :
                <div className="admin-base-layout">
                    <motion.div
                        initial={{ opacity: 0, x: "-50%" }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: "50%" }}
                        transition={{ duration: 0.3, ease: "easeInOut" }}
                    >
                        <div className="">
                            <nav className="row gx-0 mx-0">
                                <div className="col light-black-background d-flex justify-content-between justify-content-sm-center align-items-center first-column">

                                    <GiHamburgerMenu
                                        className="pointer mobile-hamburger ms-2" fill={"var(--golden)"} size={"2.0rem"}
                                        onClick={() => toggleOffCanvas()}
                                    />

                                    {
                                        (logo) &&
                                        <Link to="/">
                                            <img className="admin-logo" src={logo} alt="logo" />
                                        </Link>
                                    }
                                </div>
                                <div className="col sticky-top">
                                    <AdminNavigation
                                        sideBar={sideBar}
                                        toggleSidebar={toggleSidebar}
                                        offCanvas={offCanvas}
                                        toggleOffCanvas={toggleOffCanvas}
                                        selectedLeftBarData={selectedLeftBarData}
                                    />
                                </div>
                            </nav>
                            <div className="row gx-0 mx-0 content-area">
                                <aside className={`sidebar ${sideBar ? "col first-column" : "hidden"}`}>
                                    <AdminLeftSideBar
                                        selectedLeftBarData={selectedLeftBarData}
                                        setSelectedLeftBarData={setSelectedLeftBarData}
                                        leftSideBarData={homeLeftData}
                                        mobileScreen={false}
                                        toggleOffCanvas={toggleOffCanvas}
                                    />
                                </aside>
                                <div className={`main-content ${sideBar ? "collapsed col" : "expanded col w-100"}`}>
                                    <div className="row gx-0">
                                        <div className="col-12">
                                            <div className="main-content-area">
                                                {children}
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <AdminFooter />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            (offCanvas) &&
                            <>
                                <Offcanvas show={offCanvas}
                                    placement={"start"}
                                    onHide={toggleOffCanvas}
                                    className="custom-offcanvas dark-black-background"
                                    style={{
                                        width: "230px",
                                    }}
                                // {...props}
                                >
                                    {/* <Offcanvas.Header className="p-0" closeButton>
                                        <Offcanvas.Title>Offcanvas</Offcanvas.Title>
                                    </Offcanvas.Header> */}
                                    <Offcanvas.Body className="p-0">
                                        <div className="row gx-0 mx-0">
                                            <aside className={`sidebar`}>
                                                <AdminLeftSideBar
                                                    selectedLeftBarData={selectedLeftBarData}
                                                    setSelectedLeftBarData={setSelectedLeftBarData}
                                                    leftSideBarData={homeLeftData}
                                                    mobileScreen={true}
                                                    toggleOffCanvas={toggleOffCanvas}
                                                />
                                            </aside>
                                        </div>
                                    </Offcanvas.Body>
                                </Offcanvas>
                            </>
                        }
                    </motion.div>
                </div>
            }
        </div>
    );
};

export default AdminBaseLayout;