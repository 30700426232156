import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { rootUrl } from "../App";
import { useDispatch } from "react-redux";
import { setInnerLoaderFalse, setInnerLoaderTrue } from "../Redux/loaderSlice";
import { addDataIntoCache, getCacheData } from "../Redux/cacheSlice";

export const useAdminStatics = () => {
    const appExtraParamForStatics = `?app=affiliate`;
    const dispatch = useDispatch();
    const [mirrorLinkData, setMirrorLinkData] = useState({});
    const [logo, setLogo] = useState([]);

    const [sliderImgList, setSliderImgList] = useState(null);
    const [appBG, setAppBG] = useState([]);

    const [percentage, setPercentage] = useState(((new Date())?.getSeconds()) % 100);
    const [progressBar, setProgressBar] = useState(true);

    const location = useLocation();
    let pageName = "";

    const splitForSingleImageBannersPage = location?.pathname.split("/")[1];

    pageName = splitForSingleImageBannersPage;

    useEffect(() => {
        fetch(`${rootUrl}/mirror-links/${appExtraParamForStatics}`)
            .then(res => {
                if (!res.ok) throw res;
                else return res.json();
            })
            .then(data => {
                if (data?.status === "ok") {
                    // console.log(data);
                    setLogo(rootUrl + data?.data?.link_logo);
                    window.document.title = document.title = data?.data?.web_title;
                    setMirrorLinkData(data?.data);
                }
            })
            .catch(err => { })
    }, [appExtraParamForStatics])


    useEffect(() => {
        const fetchStaticsData = async () => {
            dispatch(setInnerLoaderTrue());

            const cacheDataOfAppBG = await getCacheData('appBGCache', `${rootUrl}/statics/banners/app_background_image`);

            const cacheDataOfSliders = await getCacheData(`slidersCache`, `${rootUrl}/statics/banners/`);

            if (cacheDataOfAppBG &&
                cacheDataOfSliders) {

                // Get the current time
                const currentTime = new Date();

                // Assuming you have a cached time stored as a Date object
                const cachedTimeStringOfAppBG = cacheDataOfAppBG?.updateDate; /* Get the cached time from wherever it's stored */

                const cachedTimeStringOfSliders = cacheDataOfSliders?.updateDate;

                // Convert the cached time string to a Date object
                const cachedTimeOfAppBG = new Date(cachedTimeStringOfAppBG);

                const cachedTimeOfSliders = new Date(cachedTimeStringOfSliders);

                // Define a threshold (in milliseconds) for when to hit the API
                const cacheExpirationThreshold = (60 * 1000) * 60 * 24; // 24 hour (adjust as needed)

                // Calculate the time difference in milliseconds
                const timeDifferenceOfAppBG = currentTime - cachedTimeOfAppBG;

                const timeDifferenceOfSliders = currentTime - cachedTimeOfSliders;

                let sliderData = (cacheDataOfSliders?.data?.filter(item => item?.page_list_id?.name === (pageName || "home")));

                if ((timeDifferenceOfAppBG < cacheExpirationThreshold) && (timeDifferenceOfSliders < cacheExpirationThreshold)) {
                    setAppBG(cacheDataOfAppBG?.data);

                    setSliderImgList(sliderData);
                    return;
                }

                if (cacheDataOfAppBG) {
                    if (timeDifferenceOfAppBG < cacheExpirationThreshold) {
                        setAppBG(cacheDataOfAppBG?.data);
                        return null;
                    }
                }

                if (cacheDataOfSliders) {
                    if ((timeDifferenceOfSliders < cacheExpirationThreshold)) {
                        setSliderImgList(sliderData);
                        return null;
                    }
                }
            }


            fetch(`${rootUrl}/statics/banners/${appExtraParamForStatics}`)
                .then(res => res.json())
                .then(data => {
                    // console.log(data);
                    if (data?.status === "ok" && data?.data?.length > 0) {
                        // console.log(pageName);
                        let sliderData = (data?.data?.filter(item => item?.page_list_id?.name === (pageName || "home")));
                        setSliderImgList(sliderData);

                        addDataIntoCache(`slidersCache`, `${rootUrl}/statics/banners/`, {
                            data: data?.data,
                            updateDate: new Date()
                        });

                        // app background image is fixed not dependent on page
                        let bgData = (data?.data?.filter(item => item?.page_list_id?.name === "app_background_image"));
                        setAppBG(bgData);

                        addDataIntoCache('appBGCache', `${rootUrl}/statics/banners/app_background_image`, {
                            data: bgData,
                            updateDate: new Date()
                        });
                    }
                })
        };
        fetchStaticsData();
        dispatch(setInnerLoaderFalse());
    }, [pageName, dispatch, appExtraParamForStatics]);


    useEffect(() => {
        const progressTimer = 500;
        if (
            logo
        ) {
            if (percentage === 100) {
                setTimeout(() => {
                    setProgressBar(false);
                }, progressTimer);
            } else {
                setPercentage(100);
            }
        }
        else {
            setTimeout(() => {
                setPercentage(80);
            }, progressTimer);
        }
    }, [logo, appBG, percentage,]);

    return {
        mirrorLinkData,
        logo,
        sliderImgList,
        progressBar,
        percentage,
    };
};