import React from "react";
import "./AdminNavigation.css";

import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// import ThemeChanger from "../ThemeChanger/ThemeChanger";
// import { useAdminStatics } from "../../../hooks/useAdminStatics";
import { fetchLogoutUser, userDetail } from "../../../Redux/userSlice";

// import images and icons

// import { FaBell } from 'react-icons/fa';
import { GiHamburgerMenu } from "react-icons/gi";

import { useThemeColor } from "../../../hooks/useThemeColor";
import { userKey } from "../../../App";
// import { useLiveNotifications } from "../../../hooks/useLiveNotifications";

const signUpIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/NavigationIcons/signup-icon.svg";
const loginIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/NavigationIcons/login-icon.svg";

// const refreshIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/NavigationIcons/refresh-icon.svg";
// const plusIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/NavigationIcons/plus-icon.svg";

const logoutIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/NavigationIcons/logout-icon.svg";
const logoutDarkIcon = process.env.PUBLIC_URL + "/Assets/Images/Icons/NavigationIcons/logout-dark-icon.svg";

const AdminNavigation = ({ sideBar, toggleSidebar, offCanvas, toggleOffCanvas, selectedLeftBarData, }) => {
    const { colorMode, } = useThemeColor();

    // const { logo } = useAdminStatics();
    // const { unseenNotifications, } = useLiveNotifications();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const user = useSelector(userDetail);
    // const userWallet = useSelector(currentUserWallet);

    // const [activeRefresh, setActiveRefresh] = useState(false);

    // const refreshBtn = () => {
    //     setActiveRefresh(true);
    // };


    // useEffect(() => {
    //     let refreshTimer;
    //     if (activeRefresh) {
    //         // Start refreshing

    //         dispatch(fetchLoginUserWallet());
    //         refreshTimer = setTimeout(() => {
    //             // Stop refreshing after 1 second
    //             setActiveRefresh(false);
    //         }, 1000);
    //     }
    //     // else {
    //     //     // Start a timer to refresh after 30 seconds if not already refreshing
    //     //     refreshTimer = setTimeout(() => {
    //     //         setActiveRefresh(true);
    //     //     }, 30000);
    //     // }
    //     return () => clearInterval(refreshTimer);
    // }, [activeRefresh, dispatch]);

    // console.log(user, userWallet);

    const logoutFunction = async () => {
        // dispatch(setInnerLoaderTrue());
        // gsBalanceGet();
        let currData = dispatch(fetchLogoutUser());
        if (currData?.error) return;
        localStorage.removeItem(userKey);
        navigate("/");
    }

    return (
        <div className="row gx-0 justify-content-between align-items-center py-1 px-2 header">
            {/* <div className="col-3 col-sm-5 d-none justify-content-start justify-content-md-start">
                <Link to="/"
                // onClick={() => setSelectedFragmentID(0)}
                >
                    <img className="logo"
                        src={logo}
                        alt="logo" />
                </Link>
            </div> */}
            <div className="col-9 col-sm-4 d-flex justify-content-start justify-content-md-start align-items-center">
                <GiHamburgerMenu
                    className="pointer web-hamburger me-2 me-md-3" fill={"var(--golden)"} size={"1.5rem"}
                    onClick={() => toggleSidebar()}
                />
                <p className="mb-0 golden-text admin-nav-page-name">
                    {selectedLeftBarData?.name}
                </p>
            </div>
            {
                user?.is_login ?
                    <div className="col-3 col-sm-8 d-flex justify-content-end align-items-center">

                        {/* <Link className={(unseenNotifications?.length > 0) ? "mx-2 bet-notification-number blink-image" : "mx-2 bet-notification-number"} to={`/notifications${user?.user?.username ? ("?" + user?.user?.username) : ""}`}>
                            <FaBell size={22} color="var(--golden)" />

                            <span>{(unseenNotifications?.length > 9) ? "9+" : (unseenNotifications?.length) ? unseenNotifications?.length : ""}</span>

                        </Link> */}

                        <div
                            className="nav-user-bg-x pointer"
                            style={{
                                border: "1px solid var(--golden)",
                                borderRadius: "5px",
                            }}
                            onClick={() =>
                                logoutFunction()
                            }
                        >
                            <img className="p-1" src={(colorMode === "light") ? logoutIcon : logoutDarkIcon} alt="logout" width={"25"}
                            />
                        </div>
                        {/* <GiHamburgerMenu
                            className="pointer mobile-hamburger ms-2 ms-md-3" fill={"var(--golden)"} size={"1.5rem"}
                            onClick={() => toggleOffCanvas()}
                        /> */}
                    </div>
                    :
                    <div className="col-6 col-sm-8 d-flex justify-content-end align-items-center">
                        <Link to={"/registration"}>
                            <button className="header-button signUp-button d-none">
                                <img className="me-1"
                                    width={"11px"}
                                    src={signUpIcon}
                                    alt="signUp" />
                                {/* <Trans i18nKey="signup"> */}
                                SIGN UP
                                {/* </Trans> */}
                            </button>
                        </Link>

                        <Link to="/login" className="ms-2">
                            <button className="header-button login-button d-none">
                                <img className="me-1"
                                    width={"14px"}
                                    src={loginIcon}
                                    alt="login" />
                                LOG IN
                            </button>
                        </Link>
                    </div>
            }
        </div>
    );
};

export default AdminNavigation;