import React from 'react';


// admin pages
const MainPage = React.lazy(() => import('../../Pages/Admin/MainMenu/MainPage/MainPage'));
const Websites = React.lazy(() => import('../../Pages/Admin/MainMenu/Websites/Websites'));
const CommissionStructure = React.lazy(() => import('../../Pages/Admin/MainMenu/CommissionStructure/CommissionStructure'));
const PaymentHistory = React.lazy(() => import('../../Pages/Admin/MainMenu/PaymentHistory/PaymentHistory'));
const Account = React.lazy(() => import('../../Pages/Admin/MainMenu/Account/Account'));
const Contacts = React.lazy(() => import('../../Pages/Admin/MainMenu/Contacts/Contacts'));

const AffiliateLinks = React.lazy(() => import('../../Pages/Admin/Marketing/AffiliateLinks/AffiliateLinks'));
const PromoCodes = React.lazy(() => import('../../Pages/Admin/Marketing/PromoCodes/PromoCodes'));
const Media = React.lazy(() => import('../../Pages/Admin/Marketing/Media/Media'));

// const Summary = React.lazy(() => import('../../Pages/Admin/Reports/Summary/Summary'));
const AffiliateBetHistory = React.lazy(() => import('../../Pages/Admin/Reports/AffiliateBetHistory/AffiliateBetHistory'));
const FullReport = React.lazy(() => import('../../Pages/Admin/Reports/FullReport/FullReport'));
const MarketingTools = React.lazy(() => import('../../Pages/Admin/Reports/MarketingTools/MarketingTools'));
const PlayerReport = React.lazy(() => import('../../Pages/Admin/Reports/PlayerReport/PlayerReport'));
const SubAffiliateReport = React.lazy(() => import('../../Pages/Admin/Reports/SubAffiliateReport/SubAffiliateReport'));

// auth pages
const AdminLogin = React.lazy(() => import('../../Pages/Auth/Login/Admin/AdminLogin'));
// const FaceID = React.lazy(() => import('../../Pages/Auth/Login/FaceID'));
// const Credential = React.lazy(() => import('../../Pages/Auth/Login/Credential'));
// const Notifications = React.lazy(() => import('../../Pages/Notifications/Notifications'));

export const exceptionalPages = [
    // {
    //     id: 2,
    //     name: "Notifications",
    //     path: "/notifications",
    //     pageTitle: "Notifications",
    //     component: <Notifications />,
    //     private: true,
    // },
];

export const routesListAuth = [
    {
        id: 0,
        name: "Login",
        path: "/login",
        pageTitle: "Login",
        component: <AdminLogin />,
        public: true,
    },
    // {
    //     id: 1,
    //     name: "Face ID",
    //     path: "/faceID",
    //     pageTitle: "Face ID",
    //     component: <FaceID />,
    //     public: true,
    // },
    {
        id: 2,
        name: "Credential",
        path: "/credential",
        pageTitle: "Credential",
        component: <AdminLogin />,
        public: true,
    },
];

export const routesListWithPageName = [
    {
        id: 0,
        name: "Main Page",
        path: "/main-page",
        pageTitle: "Main Page",
        component: <MainPage />,
        private: true,
    },
    {
        id: 1,
        name: "Websites",
        path: "/websites",
        pageTitle: "Websites",
        component: <Websites />,
        private: true,
    },
    {
        id: 2,
        name: "Commission Structure",
        path: "/commission-structure",
        pageTitle: "Commission Structure",
        component: <CommissionStructure />,
        private: true,
    },
    {
        id: 3,
        name: "Payment History",
        path: "/payment-history",
        pageTitle: "Payment History",
        component: <PaymentHistory />,
        private: true,
    },
    {
        id: 4,
        name: "Account",
        path: "/account",
        pageTitle: "Account",
        component: <Account />,
        private: true,
    },
    {
        id: 5,
        name: "Contacts",
        path: "/contacts",
        pageTitle: "Contacts",
        component: <Contacts />,
        private: true,
    },
    {
        id: 6,
        name: "Affiliate Links",
        path: "/affiliate-links",
        pageTitle: "Affiliate Links",
        component: <AffiliateLinks />,
        private: true,
    },
    {
        id: 7,
        name: "Promo Codes",
        path: "/promo-codes",
        pageTitle: "Promo Codes",
        component: <PromoCodes />,
        private: true,
    },
    {
        id: 8,
        name: "Media",
        path: "/media",
        pageTitle: "Media",
        component: <Media />,
        private: true,
    },
    {
        id: 9,
        name: "Bet History",
        path: "/affiliate-bet-history",
        pageTitle: "Bet History",
        component: <AffiliateBetHistory />,
        private: true,
    },
    {
        id: 10,
        name: "Full Report",
        path: "/full-report",
        pageTitle: "Full Report",
        component: <FullReport />,
        private: true,
    },
    {
        id: 10,
        name: "Marketing Tools",
        path: "/marketing-tools",
        pageTitle: "Marketing Tools",
        component: <MarketingTools />,
        private: true,
    },
    {
        id: 11,
        name: "Player Report",
        path: "/player-report",
        pageTitle: "Player Report",
        component: <PlayerReport />,
        private: true,
    },
    {
        id: 12,
        name: "Sub-affiliate Report",
        path: "/sub-affiliate-report",
        pageTitle: "Sub-affiliate Report",
        component: <SubAffiliateReport />,
        private: true,
    },
];