import React from "react";
import "./AdminLeftSidebar.css";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { userDetail } from "../../../Redux/userSlice";
// import { useSportsExchange } from "../../../hooks/useSportsExchange";
import { useThemeColor } from "../../../hooks/useThemeColor";
// import ThemeChanger from "../ThemeChanger/ThemeChanger";

const AdminLeftSideBar = ({ selectedLeftBarData, setSelectedLeftBarData, leftSideBarData, mobileScreen = false, toggleOffCanvas, }) => {

    const { colorMode } = useThemeColor();

    const navigate = useNavigate();
    // const dispatch = useDispatch();
    const user = useSelector(userDetail);
    // const { exchangeLaunchFunction } = useSportsExchange();

    const changeStateFunc = (item) => {
        // console.log(user?.is_login, item.name);
        setSelectedLeftBarData(item);
        if (mobileScreen) {
            toggleOffCanvas();
        }
    }
    return (
        <div className="left-bar py-0">
            {
                leftSideBarData?.map((menuItem, index) => (
                    <div key={index}>
                        {
                            (menuItem?.name) &&
                            <p className="text-start menu-title fw-bold">{menuItem?.name}</p>
                        }
                        {
                            menuItem?.subMenu?.map(item =>
                                <Link
                                    key={item?.id}
                                    to={
                                        // (item?.to) !== "mood"
                                        // &&
                                        item?.to
                                    }
                                    className={(selectedLeftBarData?.name === item?.name) ? "active custom-shadow" : "sqrBtn custom-shadow"}
                                    onClick={() => changeStateFunc(item)}
                                    style={{ textDecoration: "none", }}
                                >
                                    <div
                                        className="d-flex justify-content-start align-items-center w-100 m-0 p-0"
                                    >
                                        {
                                            // item?.to === "mood" ?
                                            //     <ThemeChanger />
                                            //     :
                                            < img src={(selectedLeftBarData?.name === item?.name) ? item?.icon3 : (colorMode === "dark") ? item?.icon1 : item?.icon2} alt={item?.name} />
                                        }
                                        <p className="ps-2 ps-md-3 text-start">{(item?.to !== "mood") ? item?.name : ((colorMode === "dark") ? "Light" : (colorMode === "light") ? "Dark" : item?.name)}</p>
                                    </div>
                                </Link>
                            )
                        }
                    </div>
                ))
            }
        </div>
    );
};

export default AdminLeftSideBar;